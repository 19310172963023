import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import getLanguage from '../utils/getLanguage';

export default function Location() {
  const language = getLanguage();

  return (
    <Layout page="location">
      <SEO title="Location" />
      <div className="textContent">
        <h2>Location</h2>
        {language === 'en' ? (
          <p>
            The wedding reception will be held at{' '}
            <b>Napalai Ballroom, Dusit Thani Hotel, Bangkok</b>. Please see the map below.
          </p>
        ) : (
          <p>
            งานจัดที่ <b>ห้องนภาลัยบอลรูม โรงแรมดุสิตธานี กรุงเทพฯ</b> ดูแผนที่จาก Google Maps
            ด้านล่างได้เลยครับ
          </p>
        )}
        <br />
        <iframe
          title="map"
          width="636"
          height="500"
          frameBorder="0"
          scrolling="no"
          marginHeight={0}
          marginWidth={0}
          src="https://www.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=Dusit+Thani+Bangkok+,+Thailand+Bangkok+Thailand&amp;aq=0&amp;oq=Dusit+Thani&amp;sll=13.749557,100.512457&amp;sspn=0.030889,0.066047&amp;ie=UTF8&amp;hq=Dusit+Thani+Bangkok+,+Thailand+Bangkok+Thailand&amp;t=m&amp;ll=13.730568,100.536168&amp;spn=0.010422,0.014184&amp;z=16&amp;iwloc=A&amp;output=embed"
        />
        <br />
        <small>
          <a
            href="https://www.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=Dusit+Thani+Bangkok+,+Thailand+Bangkok+Thailand&amp;aq=0&amp;oq=Dusit+Thani&amp;sll=13.749557,100.512457&amp;sspn=0.030889,0.066047&amp;ie=UTF8&amp;hq=Dusit+Thani+Bangkok+,+Thailand+Bangkok+Thailand&amp;t=m&amp;ll=13.730568,100.536168&amp;spn=0.010422,0.014184&amp;z=16&amp;iwloc=A"
            style={{ color: '#0000FF', textAlign: 'left' }}
          >
            View Larger Map
          </a>
        </small>
        <h2>Getting there</h2>
        {language === 'en' ? (
          <>
            There are many ways to get to the hotel
            <ul>
              <li>
                <b>BTS SkyTrain</b>
                &nbsp;Saladaeng Station
              </li>
              <li>
                <b>MRT</b>
                &nbsp;Silom Station
              </li>
              <li>
                <b>Taxi</b>
                &nbsp;Just tell the driver &quot;Dusit Thani&quot;.
              </li>
              <li>
                <b>Driving</b>
                &nbsp;Please see the map above.
              </li>
            </ul>
          </>
        ) : (
          <>
            สามารถเดินทางมาโรงแรมได้หลายวิธี
            <ul>
              <li>
                <b>รถไฟฟ้า BTS</b>
                &nbsp;ลงสถานีศาลาแดง
              </li>
              <li>
                <b>รถไฟฟ้าใต้ดิน MRT</b>
                &nbsp;ลงสถานีสีลม
              </li>
              <li>
                <b>รถแท็กซี่</b>
                &nbsp;บอกคนขับว่าไปโรงแรมดุสิตธานี
              </li>
              <li>
                <b>ขับรถมาเอง</b>
                &nbsp;ดูแผนที่ด้านบนค่ะ
              </li>
            </ul>
          </>
        )}
      </div>
      <p>&nbsp;</p>
    </Layout>
  );
}
